.all-unset{
  all:unset;
}

.text-color__p {
  color: #2470c2 !important;
}

.bg-color__patient {
  background-color: #2470c2 !important;
  background: #2470c2;
}

.p-logo {
  background-color: rgb(58, 248, 58);
  width: 23px;
  height: 25px;
  border-radius: 50%;
  margin: 0 auto;
  text-transform: uppercase;
  font-size: 15px;
  display: grid;
  aspect-ratio: 1;
  place-items: center;
  font-family: "Mulish";
}

.pg-title {
  color: #14328c;
  font-weight: 900;
  /* font-family: "Varela Round"; */
  font-family: "Mulish";
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
  letter-spacing: 3.83px;
}

.pd-pg-title {
  color: #2470c2;
  font-weight: 900;
  /* font-family: "Varela Round"; */
  font-family: "Mulish";
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
  letter-spacing: 3.83px;
}

.reg-header {
  color: #14328c;
  font-family: "Mulish";
  font-size: 63px;
  font-weight: 300;
  line-height: 70px;
  letter-spacing: 0.57px;
}

.reg-header span {
  font-weight: 400;
  font-family: "Varela Round";
  letter-spacing: 0px;
}

.reg-sub-header {
  color: #14328c;
  font-family: "Varela Round";
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.25px;
}

.list {
  padding: 0;
  margin: 0;
}

.hr {
  width: 100%;
  color: #212529;
  line-height: 24px;
}

.info-icon-blue {
  color: rgb(91, 158, 199);
  font-size: 23px;
}

.reg-list {
  list-style: none;
  font-weight: 300;
  font-size: 20px;
  line-height: 31px;
  letter-spacing: 1px;
}

ul.list li:before {
  content: "\002022";
  position: absolute;
  left: 0;
}

.reg-list li:before {
  color: #14328c;
}

.reg-list li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 9px;
}

.reg-list li a {
  font-weight: 700;
}

.pg-card {
  padding: 25px;
  /* background: #14328c !important; */
  border-radius: 4px;
  min-height: 633px;
  position: relative;
}

.pq-card {
  padding: 25px;
  /* background: #14328c !important; */
  border-radius: 4px;
  min-height: 390px;
  position: relative;
}

.pt-card {
  padding: 25px;
  /* background: #14328c !important; */
  border-radius: 4px;
  min-height: 390px;
  position: relative;
}

.pg-card__content-height {
  padding: 25px;
  background: #14328c;
  border-radius: 8px;
  position: relative;
}

.pg-card__btn {
  position: absolute;
  bottom: 20px;
}

.pg-heading {
  color: #14328c;
  font-family: Avenir-Heavy, sans-serif;
  font-size: 28px;
  font-family: Avenir-Heavy, sans-serif;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.pg-sub-heading {
  color: #14328c;
  /* font-family: "Varela Round"; */
  font-family: "Mulish";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 1.25px;
  line-height: 32px;
}

.pd {
  padding: 18px 30px;
}

.info-fx {
  display: flex;
  gap: 15px;
  align-items: center;
  /* width: max-content; */
}

.pd-pg-heading {
  color: #2470c2;
  /* font-family: "Varela Round"; */
  font-family: "Mulish";
  font-size: 25px;
  font-weight: 900;
  letter-spacing: 1.25px;
  line-height: 32px;
  text-transform: uppercase;
}

.pd-pg-report {
  color: #2470c2;
  /* font-family: "Varela Round"; */
  font-family: "Mulish";
  font-size: 28px;
  font-weight: 400;
  line-height: 32px;
  text-transform: capitalize;
}

.pg-heading__sub {
  color: #000000;
  font-family: "Mulish";
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 1.67px;
  line-height: 32px;
  text-transform: uppercase;
}

.pg-heading__count {
  color: #fff;
  /* font-family: "Varela Round"; */
  font-family: Avenir-Heavy, sans-serif;
  font-size: 28px;
  font-weight: 900;
  line-height: 34px;
  text-align: right;
  margin: 10px 10px 5px 0;
  /* width: 32%; */
}

.pg-text {
  color: #000;
  font-family: Muli-Light, sans-serif;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  line-height: 32px;
  margin-top: 3;
}

.link-to-prs {
  color: #14328c !important;
  text-decoration: underline !important;
  cursor: pointer !important;
  text-underline-offset: 5px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.pg-text__small {
  color: #000;
  font-family: Muli-Light, sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  line-height: 1.5;
  margin-top: 3;
}

.pg-text__bold {
  color: #000000;
  font-family: Avenir-Light, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.pg-text__bold__small {
  font-family: Avenir-Light, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.pg-text__patient {
  color: #fff;
  font-family: Muli-Light, sans-serif;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  line-height: 32px;
  margin: 10px 0px 5px 15px;
}

.pg-link {
  color: #14328c;
  font-family: Muli-Light, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  text-decoration: none;
  display: block;
  margin-right: 10px;
  cursor: pointer;
}

.bg-card {
  background: #f6f6f6;
}

.px-8 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.supplement_notes {
  color: #888;
  font-family: Mulish;
  font-weight: 400;
  font-size: 13px;
}

.pg-note {
  color: #888;
}

/* custom radio button */
.pg_radio {
  position: relative;
  cursor: pointer;
  display: block;
}

.pg_radio input {
  display: none;
  position: relative;
}

.pg_radio__btn {
  position: absolute;
  top: 7px;
  left: -38px;
  height: 20px;
  width: 20px;
  background: #fff;
  border: 2px solid #0288d1;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.pg_radio:hover input~.pg_radio__btn,
.pg_radio input:focus~.pg_radio__btn {
  display: block;
}

.pg_radio input:checked~.pg_radio__btn::after {
  display: block;
}

.pg_radio__btn::after {
  content: "";
  position: absolute;
  display: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: #0288d1;
  border-radius: 50%;
}

.mr-0 {
  margin-right: 0px;
}

.row>* {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

/* custom radion button end */

/* custom checkbox */
.pg_checkbox {
  position: relative;
  cursor: pointer;
  display: block;
  left: -18px;
}

.pg_checkbox input {
  display: none;
  position: relative;
}

.pg_checkbox__btn {
  position: absolute;
  top: 7px;
  left: -30px;
  height: 20px;
  width: 19px;
  background: #fff;
  border: 2px solid #0288d1;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.pg_checkbox input:focus~.pg_checkbox__btn {
  display: block;
}

.pg_checkbox input:checked~.pg_checkbox__btn::after {
  display: block;
}

.pg_checkbox__btn::after {
  content: "✓";
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  display: none;
  left: 50%;
  top: 50%;
  line-height: 18px;
  color: #fff;
  border-radius: 2px;
  background-color: #0288d1;
  border-left: 3px solid #0288d1;
  border-right: 3px solid #0288d1;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
}

/* checkbox customization end */

.file-picker-container input[type="file"] {
  display: none;
}

.dropdown-menu {
  border-radius: 8px;
}

.welcome-font {
  font-size: 20px;
}

.btn-light.step-1-btn {
  padding: 8px 30px;
  font-size: 16px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
  display: inline-block;
  background-color: transparent !important;
  color: #ffffff !important;
}

.btn-light.step-2-btn {
  padding: 8px 30px;
  font-size: 16px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
  display: inline-block;
  background-color: #ffffff !important;
  color: #2470c2 !important;
}

/* .btn-light.step-2-btn, .btn-light.step-2-btn:hover{
  background-color: #ffffff !important;
  color: #2470c2 !important;
} */

.btn-light.step-2-btn:hover {
  background-color: #2470c2 !important;
  color: #ffffff !important;
}

.btn-light.step-1-btn:hover {
  background-color: #ffffff !important;
  color: #2470c2 !important;
}

.pt-pg-heading {
  font-size: 26px !important;
  letter-spacing: 5.2px !important;
}

.pt-pg-title {
  font-size: 18px !important;
  letter-spacing: 3.18px !important;
}

.pt-pg-content {
  font-size: 18px;
}

.pd-pg-heading {
  font-size: 28px !important;
  color: #2470c2;
}

.pd-btn {
  background-color: transparent !important;
  color: #2470c2 !important;
  border: 2px solid #2470c2 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  margin: 0px 15px;
  padding: 15px 30px;
  letter-spacing: 0px !important;
  line-height: 32px;
}

.pd-primary-btn {
  background-color: #2470c2 !important;
  color: #ffff !important;
  border: 2px solid #2470c2 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  margin: 0px 15px;
  padding: 15px 30px;
  letter-spacing: 0px !important;
}

.pd-btn:hover {
  background-color: #2470c2 !important;
  color: #ffff !important;
}

.pd-primary-btn:hover {
  background-color: #ffff !important;
  color: #2470c2 !important;
}

.move-status-left {
  margin-left: 15px !important;
}

.reg {
  font-size: 78%;
  line-height: 2;
  vertical-align: top;
}

.reg-button {
  font-size: 78%;
  line-height: 0.8;
  vertical-align: top;
}

.react-datepicker select {
  padding: 5px 0px !important;
}

.react-datepicker__header div:first-child {
  margin: 5px !important;
}

@media (min-width: 1000px) {
  .dashboard-container {
    padding: 50px 180px !important;
  }

  .pg-title {
    color: #14328c;
    text-transform: uppercase;
    font-size: 35px;
    line-height: 70px;
  }

  .pg-card {
    padding: 25px;
    background: #f6f6f6;
    min-height: 633px;
    position: relative;
  }

  .pq-card {
    padding: 25px;
    background: #f6f6f6;
    min-height: 390px;
    position: relative;
  }

  .pg-card__content-height {
    padding: 25px;
    background: #f6f6f6;
    border-radius: 8px;
    position: relative;
  }

  .pg-heading {
    font-size: 30px;
    line-height: 34px;
    letter-spacing: 2.61px;
  }

  .pg-heading__sub {
    font-size: 18px;
  }

  .pg-heading__count {
    color: #fff;
    font-size: 28px;
    line-height: 34px;
    text-align: right;
    margin: 10px 10px 5px 0;
    width: 32%;
  }

  .pg-text {
    color: #000;
    font-size: 18px;
    font-weight: 300;
    text-align: left;
    line-height: 32px;
    margin-top: 3;
  }

  .pg-text__bold {
    color: #000000;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
  }

  .pg-text__bold__small {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }

  .pg-text__patient {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    text-align: left;
    line-height: 32px;
    margin: 10px 0px 5px 15px;
  }

  .pg-link {
    color: #14328c;
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    text-decoration: none;
    display: block;
    margin-right: 10px;
    cursor: pointer;
  }

  .bg-card {
    background: #f6f6f6;
  }
}

@media only screen and (max-width: 600px) {
  .review-question_change {
    font-size: 1rem;
  }

  .patient-answers-title {
    font-size: 20px;
  }
}

@media (min-width: 360px) and (max-width: 575px) {
  .reg-header {
    font-size: 50px;
  }

  .patient-answers-title {
    font-size: 20px;
  }
}

.pg_radio__1 {
  position: relative;
  cursor: pointer;
  display: block;
}

.pg_radio__1 input {
  display: none;
  position: relative;
}

.pg_radio__1__btn {
  /* position: absolute; */
  top: 7px;
  left: -8px;
  height: 20px;
  width: 20px;
  background: #fff;
  border: 2px solid #0288d1;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.pg_radio__1:hover input~.pg_radio__1__btn,
.pg_radio__1 input:focus~.pg_radio__1__btn {
  display: block;
}

.pg_radio__1 input:checked~.pg_radio__1__btn::after {
  display: block;
}

.pg_radio__1__btn::after {
  content: "";
  position: relative;
  display: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: #0288d1;
  border-radius: 50%;
}

#email-input__addpatient:disabled {
  cursor: not-allowed;
}

.practitioner-batch-checkbox .pg_checkbox__btn {
  top: -17px;
}

.practitioner-table .search-input {
  width: 45%;
}

.admin-table .search-input {
  width: 74%;
}

.normal-admin .search-input {
  width: 100%;
}

.add-practitioner-modal .close,
.edit-patient-modal .close,
.add-patient-modal .close {
  background-color: white;
  border: none;
}

.add-practitioner-modal .close>span:first-of-type,
.add-patient-modal .close>span:first-of-type,
.edit-patient-modal .close>span:first-of-type {
  font-size: 30px;
}

.management-forms .nav-tabs {
  margin-bottom: 30px;
}

.connect-stripe-btn {
  padding-top: 15px;
}

.custom-bagde-color {
  font-size: 12px !important;
  border: 1px solid #888888 !important;
  color: #888888 !important;
  padding: 0.45em 0.65em !important;
  display: inline-block;
  font-weight: 700;
  line-height: 21px;
  margin-left: 12px;
  vertical-align: top;
  text-align: center;
  border-radius: 0.25rem;
  font-weight: 900;
  color: var(--pg_primary);
}

.terms-conditions .agreement-points {
  font-weight: bold;
}

.terms-conditions li,
.terms-conditions p {
  font-size: 17px;
  line-height: 1.7em;
  font-family: Arial, Helvetica, sans-serif;
}

.terms_header {
  border: 1px solid;
  text-align: center;
  margin-top: 40px;
  font-weight: 600;
}

.agree_btn_style {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 87%;
  min-width: 0;
  max-width: 100%;
}

.answers-modal .modal-body {
  height: 80vh;
  overflow-y: scroll;
}

.genetic-error-message {
  text-align: left;
  width: 85%;
  margin: 0 auto;
}

.select-tooltip {
  color: rgb(20, 50, 140);
  margin-left: 5px;
  position: absolute;
  right: 10;
  margin-top: 15px;
}

.genetic-error-message {
  text-align: left;
  width: 85%;
  margin: 0 auto;
}

@media only screen and (max-width: 574px) {
  .pd-pg-title {
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
  }

  .patient-answers-title {
    font-size: 20px;
  }

  .pd-pg-report {
    font-size: 15px;
  }

  .pg-link {
    font-size: 15px;
  }

  .table-column-clone div:first-child h5 {
    padding-left: 0px !important;
  }

  .table-column-clone h5 {
    font-size: 15px !important;
  }

  .term_ul_style li {
    list-style: disc;
    font-size: 14px;
  }

  .terms_header {
    border: 1px solid;
    text-align: center;
    margin-top: 40px;
    font-weight: 600;
  }

  .agree_btn_style {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 87%;
    min-width: 0;
    max-width: 100%;
  }

  .answers-modal .modal-body {
    height: 80vh;
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 574px) {
  .pd-pg-title {
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
  }

  .patient-answers-title {
    font-size: 20px;
  }

  .pd-pg-report {
    font-size: 15px;
  }

  .pg-link {
    font-size: 15px;
  }

  .table-column-clone div:first-child h5 {
    padding-left: 0px !important;
  }

  .table-column-clone h5 {
    font-size: 15px !important;
  }

  .table-column-clone p,
  .table-column-clone div {
    font-size: 13px !important;
  }

  .table-column-clone p {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .justify-row {
    justify-content: space-around;
  }

  .report-btn {
    margin-top: 15px;
  }

  .btn-px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .progress-status-wrapper {
    height: fit-content !important;
  }

  .progress-status-title {
    font-size: 55% !important;
  }

  .progress-status-title>div {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .skip-q-footer .btn {
    width: 47%;
    font-size: 10px !important;
    float: left;
  }

  .skip-q-footer {
    display: block;
  }

  .genetic-error-message {
    text-align: left;
    width: 100%;
    margin: 0 auto;
    font-size: 13px !important;
  }
}

.email-error-wrapper {
  position: absolute;
  right: 0px;
  width: 18%;
  margin-right: 2%;
  top: 22%;
  font-size: 15px;
  line-height: 1.5em;
}

.email-input {
  text-transform: lowercase;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}

::placeholder {
  /* Recent browsers */
  text-transform: none;
}

.management-forms .nav-tabs .nav-link.disabled {
  color: #6c757d !important;
}

.chip {
  display: inline-flex;
  flex-direction: row;
  background-color: #e5e5e5;
  border: none;
  cursor: default;
  height: 36px;
  outline: none;
  padding: 0;
  font-size: 14px;
  font-color: #333333;
  font-family: "Open Sans", sans-serif;
  white-space: nowrap;
  align-items: center;
  border-radius: 16px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
  margin-right: 5px;
}

.chip-head {
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #32c5d2;
  font-size: 1.25rem;
  flex-shrink: 0;
  align-items: center;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  width: 36px;
  color: #fff;
  height: 36px;
  font-size: 20px;
  margin-right: -4px;
}

.chip-content {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}

.chip-svg {
  color: #999999;
  cursor: pointer;
  height: auto;
  margin: 4px 4px 0 -8px;
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
}

.chip-svg:hover {
  color: #666666;
}

.gs-list {
  box-shadow: 0 6px 8px rgb(0 0 0/30%);
  border-radius: unset !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  top: 57.5px;
  z-index: 9999;
  max-height: 400px;
  overflow-y: scroll;
}

.gs-list::-webkit-scrollbar {
  width: 10px;
  background-color: rgb(0 0 0/10%);
  color: #000000;
}

.gs-list::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px grey;
  border-radius: 4px;
  background-color: var(--pg_primary);
  cursor: pointer !important;
}

.gs-list__item:last-of-type {
  border-bottom: unset;
}

.gs-list__item {
  border-bottom: 0.5px solid rgb(0 0 0/10%);
  transition: all 0.2s ease-in-out;
  border-radius: 8px;
}

.gs-list__item:hover {
  border-radius: 8px;
  box-shadow: 0 4px 4px rgb(0 0 0/20%);
  background: rgb(0 0 0/3%);
  cursor: pointer;
}

/* .pl-pr_margin {
  margin-left: 55% !important;
} */

#arrow_1 .arrow {
  cursor: pointer;
  display: block;
  width: 15px;
  height: 5px;
  position: absolute;
  right: 10px;
  top: 20px;
}

#arrow_1 .arrow span,
#arrow_1 .arrow:before,
#arrow_1 .arrow:after {
  background: var(--pg_primary);
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  position: absolute;
}

#arrow_1 .arrow:before {
  transform: rotate(-45deg);
}

#arrow_1 .arrow:after {
  transform: rotate(45deg);
}

#arrow_1 .arrow span {
  width: 0;
}

.gs-list__item:hover #arrow_1 .arrow span {
  width: 42.42px;
}

#arrow_1 .arrow.arrow--right span,
#arrow_1 .arrow.arrow--right:before,
#arrow_1 .arrow.arrow--right:after {
  right: 0;
  transform-origin: right 50%;
}

#arrow_1 .arrow.arrow--right:before,
#arrow_1 .arrow.arrow--right:after {
  transition: right 0.3s 0.05s;
}

#arrow_1 .arrow.arrow--right span {
  transition: width 0.3s, right 0.3s 0.05s;
}

.gs-list__item:hover #arrow_1 .arrow.arrow--right span,
.gs-list__item:hover #arrow_1 .arrow.arrow--right:before,
.gs-list__item:hover #arrow_1 .arrow.arrow--right:after {
  right: -5px;
}