.pg-action-sheet {
    &-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: flex-end;
        justify-content: center;
        z-index: 999;
        transition: opacity 0.3s ease;

        &-container {
            width: 100%;
            background-color: white;
            border-radius: 8px 8px 0 0;
            overflow: hidden;
            transform: translateY(100%);
            transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);

            &-content {
                padding: 16px;
                /* Additional styling for the action sheet content */
            }

            &-buttons {
                display: flex;
                justify-content: flex-end;
                margin-top: 8px;
            }

            /* Add more styles as needed */
        }
    }
}