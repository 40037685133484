    @import './variable.scss';

    .pg4-navbar {
        background-color: #ffffff;
        border-bottom: 1.2px solid #E3E6F1;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;

        // Styles for the logo
        .logo {
            width: 184px;
            height: 36px;

            a:focus {
                outline: 2px solid $primary;
            }
        }

        // Styles for the links in the center
        .links {
            margin: 0;
            display: flex;
            gap: 10px;
            align-items: center;
            list-style: none;
            margin-top: 5px;

            li {
                // border-bottom: 3px solid #fff;
                position: relative;

                // &:hover,
                // &:focus,
                // &:active {
                //     border-bottom: 3px solid #14328c;
                // }
            }

            li button {
                outline: none;
                background: #ffffff;
                color: $primary;
                font-family: Mulish, sans-serif;
                font-weight: 600;
                text-decoration: none;
                position: relative;
                padding: 22px 10px;
                border-radius: 0 !important;
                border: unset;
                letter-spacing: 1px;
                text-transform: uppercase;
                // border-bottom: 4px solid $primary;
                border-bottom: 4px solid #fff;

                &:hover {
                    border-bottom: 4px solid $primary;
                }

                &:focus,
                &:active {
                    outline: 2px solid $primary;
                }
            }

            .pg4-dropdown-menu {
                position: absolute;
                top: calc(100% + 0px);
                left: 0;
                background: #fff;
                border-radius: 0px;
                list-style: none;
                display: none;
                width: 250px;
                min-width: 100%;
                border: 1px solid #d7d7d7;
                display: flex;
                flex-direction: column;
                padding: 0 !important;
                z-index: 1;

                .pg4-dropdown-menu__item {
                    text-decoration: none;
                    border: none;
                    padding: 10px 10px !important;
                    width: 100%;
                    margin: unset !important;
                    // background: rgba(0, 0, 0, 0.9);
                    transition: background 0.2s ease-in;

                    &.active {
                        background: rgba(0, 0, 0, 0.1);

                    }

                    &:focus {
                        background: rgba(0, 0, 0, 0.1);
                        outline: 2px solid $primary;
                    }

                    &:hover {
                        background: rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }

        .account-actions {
            .account-actions__link {
                display: inline-block;
                border-radius: 5px;
                padding: 8px 20px;
                font-family: Mulish, sans-serif;
                text-decoration: none;
                font-weight: 600;
                font-size: 16px;
                letter-spacing: 0.8px;
                border-radius: 10px;
                background: #fff;
                color: $primary;
                margin: 0 5px;

                &:focus {
                    background: rgba(0, 0, 0, 0.1);
                    outline: 2px solid $primary;
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.1);
                }

                &.primary {
                    background: $primary;
                    color: #fff;

                    &:focus {
                        outline: 4px solid #E3E6F1;
                        background: primary;
                    }
                }
            }
        }

        .sidebar-toggle {
            outline: none;
            border: none;
            color: $primary;
            border-radius: 5px;
            font-family: Mulish, sans-serif;
            font-weight: 600;
            background-color: #fff;
            margin: 0 5px;
            font-size: 28px;

            &:focus {
                background: rgba(0, 0, 0, 0.1);
                outline: 2px solid $primary;
            }

            &:hover {
                background: rgba(0, 0, 0, 0.1);
            }
        }
    }

    .pg4-sidebar {
        font-family: Mulish, sans-serif;
        font-weight: 600;
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: clamp(300px, 75vw, 350px);
        display: flex;
        flex-direction: column;
        font-size: 20px;
        justify-content: space-between;
        z-index: 99 !important;

        nav {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .close-btn-container {
                text-align: right;

                button {
                    font-size: 24px;
                    color: #fff;
                    text-align: center;

                    &:focus {
                        background: rgba(0, 0, 0, 0.2);
                    }
                }
            }

            ul {
                padding: 0;
                align-items: stretch;
                justify-content: flex-start;

                li {
                    list-style: none;
                    width: 100% !important;

                    button {
                        padding: 10px;
                        background: none;
                        color: white;
                        outline: none;
                        border: none;
                        width: 100% !important;
                        margin: 10px 0 0 0;
                        font-weight: 800;

                        &:focus {
                            // border: 1px solid #fff;
                            background: rgba(0, 0, 0, 0.2);
                        }

                    }

                }

                ul {
                    padding-left: 20px;

                    li a {
                        font-family: Mulish, sans-serif;
                        font-weight: 600;
                        margin: 10px 0;
                        display: block;
                        color: #fff !important;
                        font-weight: 600;
                        text-decoration: none !important;
                        padding: 5px;

                        &.active {
                            border-bottom: 1px solid #fff;
                            background: rgba(0, 0, 0, 0.2);
                        }
                    }
                }
            }

            .account-actions {
                display: flex;
                justify-content: space-between;
                gap: 4px;
                margin: 10px;

                .account-actions__link {
                    width: 100%;
                    display: block;
                    background: #fff;
                    color: $primary !important;
                    font-weight: 600;
                    border: 0;
                    border-radius: 12px;
                    height: 50px;
                    line-height: 2.5;
                    text-align: center;
                    text-decoration: none;

                    &.primary {
                        background: none;
                        color: #fff !important;
                    }
                }
            }
        }
    }

    #register-dropdown+.dropdown-menu {
        transform: translate3d(0px, -45px, 0px) !important;
    }