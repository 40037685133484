.banner-img {
  position: relative;
  height: 100vh;
  overflow: hidden;
  top: -80px;
  margin-bottom: -80px;
}

.pgs-image {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pgs-title {
  color: var(--pg_primary);
  font-size: 56px;
  font-family: Avenir-Heavy, sans-serif;
  font-weight: 400;
  line-height: 70px;
}

.pgs-title__xl {
  font-size: 63px;
}

.pgs-title__v {
  font-family: Avenir-Heavy, sans-serif;
  display: inline-block !important;
}

.pgs-heading {
  font-size: 28px;
  font-family: Avenir-Heavy, sans-serif;
  line-height: 36px;
}

.pgs-heading__sm {
  font-size: 24px;
  font-family: Avenir-Heavy, sans-serif;
  font-weight: 600;
  line-height: 36px;
}

.pgs-heading__sub {
  color: var(--pg_primary);
  font-size: 22px;
  font-family: Avenir-Heavy, sans-serif;
  font-weight: 300;
  line-height: 36px;
}

.pgs-text {
  color: #000;
  font-size: 20px;
  font-family: Muli-Light, sans-serif;
  font-weight: lighter !important;
  line-height: 34px;
}

.pgs-text__sm {
  font-size: 18px;
}

.pgs-text__xs {
  font-size: 16px;
}

.pgs-table {
  width: 100%;
  border: 1px solid grey;
}

.pgs-table td,
.pgs-table th {
  padding: 20px;
  border-right: 1px solid grey;
}

.pgs-table>thead,
.pgs-table>tbody,
.pgs-table>thead>tr,
.pgs-table>tbody>tr,
.pgs-table>thead>tr>th,
.pgs-table>tbody>tr>td {
  display: block;
}

.pgs-table>tbody>tr:after,
.pgs-table>thead>tr:after {
  content: "";
  display: block;
  visibility: hidden;
  clear: both;
}

.pgs-table>tbody>tr>td,
.pgs-table>thead>tr>th {
  width: 20%;
  float: left;
}

.ff-m {
  font-family: Avenir-Heavy;
  display: inline-block;
}

.ff-v {
  font-family: Avenir-Heavy;
  display: inline-block;
}

.homepage-parallax__img {
  z-index: -999;
  border-radius: 50%;
  height: 100%;
  /* height: 600px;
  width: 600px; */
  overflow: hidden;
  -webkit-transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}

.homepage-parallax {
  height: 600px;
}

/* .homepage_list {
  min-height: 700px;
} */

.postlogin_list {
  min-height: 200px;
}

.pgs-menu-list {
  background: #fff;
  font-size: 20px !important;
  padding: 0;
  list-style: none;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 0 15px rgb(0 0 0 / 25%);
  transition: all 0.3s ease-in-out;
  z-index: 9999;
}

.pgs-menu-list>a {
  width: 100%;
  text-decoration: none !important;
  color: unset !important;
}

.pgs-menu-list a:last-child .pgs-menu-list__item {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: unset;
}

.pgs-menu-list>a.active {
  text-decoration: underline !important;
}

.pgs-menu-list__item {
  font-family: Avenir-Light, sans-serif;
  color: #000;
  padding: 20px 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  list-style: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.pgs-menu-list__item:hover {
  background-color: rgba(0, 0, 0, 0.2);
  text-decoration: underline;
}

.pgs-menu-list__item a.active {
  text-decoration: underline !important;
}

.menu-btn {
  letter-spacing: 2.78px !important;
  font-weight: 700 !important;
  line-height: 14px !important;
}

.menu-btn__active span {
  position: relative;
}

.menu-btn__active span::after {
  content: "";
  width: 100%;
  height: 2px;
  bottom: -3px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* border-bottom: 2px solid red !important; */
  background-color: #fff;
}

.react-player {
  border-radius: 10px !important;
  overflow: hidden;
  position: relative;
}

.react-player-control {
  position: absolute;
  bottom: -5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-player-control-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-player-control-center img {
  width: 40px;
}

.postlogin_list {
  min-height: 200px;
}

.pgs-image {
  height: 100vh !important;
  background-position: 20% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.meet-img {
  width: 210px !important;
  height: 220px !important;
}

/* media-query */
@media (max-width: 1400px) {
  .pgs-title__rb {
    font-size: 40px;
    line-height: 38px;
  }

  .pgs-heading__rb {
    font-size: 18px;
    line-height: 20px;

  }
}


@media (max-width: 1280px) {
  .banner-img {
    height: 100vh;
  }

  .pgs-image {
    height: 100vh !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  /* .homepage_list {
    min-height: 800px;
  } */

  .homepage-parallax {
    height: 600px;
  }
}

@media (max-width: 1024px) {
  .banner-img {
    height: 100vh;
  }

  .pgs-image {
    background-position: 0% 0px !important;
    background-size: 1100px 100vh !important;
  }

  .pgs-title {
    font-size: 36px;
    line-height: 40px;
  }

  .pgs-title__xl {
    font-size: 41px;
  }

  .pgs-heading {
    font-size: 22px;
  }

  /* 
  .homepage_list {
    min-height: 600px;
  } */

  .postlogin_list {
    min-height: fit-content;
  }

  .homepage-parallax {
    height: 500px;
  }

  .pgs-text {
    line-height: 25px;
  }
}

@media (max-width: 768px) {
  /* .homepage_list {
    min-height: fit-content;
  } */

  .banner-img {
    height: 75vh;
  }

  .pgs-image {
    background-position: 15% 0px !important;
    background-size: 1000px 90vh !important;
  }

  .pgs-title__rb {
    /* background: rgba(0, 0, 0, 0.2); */
    color: white;
    position: relative;
    z-index: 1;
  }

  .rb_hp_bg {
    background-position: 20px;
  }

  /* .rb_hp_bg::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  } */
}

@media (max-width: 550px) {
  .homepage-parallax {
    height: 300px;
  }

  .banner-img {
    height: 65vh;
    top: 0;
    margin-bottom: 0;
  }

  .pgs-image {
    background-position: -1% -160px !important;
    background-size: 1300px !important;
  }

  .banner-img .pgs-title__xl,
  .pgs-title__rb {
    color: white !important;
    margin-top: -130px;
  }

  .react-player-control>a>img {
    width: 30px;
  }

  .react-player-control {
    position: absolute;
    bottom: -15%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 450px) {
  .homepage-parallax {
    height: 300px;
  }

  .banner-img {
    height: 65vh;
  }

  .pgs-image {
    background-position: 3% -100px !important;
    background-size: 1200px !important;
  }

  .banner-img .pgs-title__xl,
  .pgs-title__rb {
    color: white !important;
    margin-top: -130px;
  }


}

@media (max-width: 400px) {
  .pgs-image {
    background-position: 6% -80px !important;
    background-size: 1200px !important;
  }
}

@media (max-width: 400px) and (max-height: 700px) {
  .pgs-image {
    background-position: 6% -130px !important;
    background-size: 1200px !important;
  }
}

@media (max-width: 375px) {
  .pgs-image {
    background-position: 6% -140px !important;
    background-size: 1200px !important;
  }

  .banner-img .pgs-title__xl,
  .pgs-title__rb {
    color: white !important;
    margin-top: -130px;
  }
}

@media (max-width: 320px) {
  .pgs-image {
    background-position: 6% -70px !important;
    background-size: 1000px !important;
  }
}

@media (max-width: 320px) and (max-height: 600px) {
  .pgs-image {
    background-position: 6% -190px !important;
    background-size: 1200px !important;
  }
}