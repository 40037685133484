.user-icon {
  font-size: 26px;
  color: #fff;
}

#header-white {
  background-color: #fff !important;
  z-index: 99999;
}

#header-transparent {
  background-color: transparent !important;
}

.pg-logo {
  /* padding-left: 15px !important; */
  /* height: 190px;
  width: 100px; */
}

.pg-logo svg {
  font-size: 120px;
  height: 50px;
  width: 190px;
}

/* header */

/* hamburger Button start */
.hamburger {
  width: 20px;
  border-radius: 50px;
  height: 3px;
  background: var(--pg_primary);
  position: absolute;
  top: 25%;
  left: 10px;
  transition: 0.5s;
}

.hamburger:before {
  top: -6px;
}

.hamburger:after {
  top: 6px;
}

/* Icon 1 */
.icon-one {
  position: absolute;
  top: 10%;
  left: 5%;
  width: 100px;
  height: 100px;
  cursor: pointer;
  /* background: var(--pg_primary); */
}

.hamburger-one:before,
.hamburger-one:after {
  content: "";
  position: absolute;
  width: 20px;
  border-radius: 50px;
  height: 3px;
  background: var(--pg_primary);
  transition: 0.5s;
}

.provider-reg-link {
  color: #fff !important;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  text-align: left;
  text-decoration: none;
  margin-right: 20px;
}

.provider-reg-link:hover {
  color: #fff;
  text-decoration: none;
}

/* hamburger Button end */
@media (min-width: 576px) {
  .hamburger {
    top: 28%;
    /* left: -1%; */
  }
}

@media (max-width: 576px) {

  #header-white,
  #header-transparent {
    background-color: #fff !important;
  }
}

@media (min-width: 768px) {
  .hamburger {
    top: 35%;
    left: 0%;
  }
}

@media (min-width: 992px) {
  .pg-logo {
    padding-left: 0px !important;
  }
}

@media (min-width: 1200px) {
  .user-icon {
    font-size: 30px;
  }

  .cart-count {
    font-size: 12px !important;
    width: 15px !important;
    height: 15px !important;
  }

  .cart-icon {
    height: 29.0625px !important;
    width: 29.0625px !important;
    font-size: 16px !important;
  }
}

@media (min-width: 1024px) and (min-height: 1366px) {}

.cart-count {
  position: absolute;
  left: 75%;
  top: -.25rem;
  background-color: red;
  font-size: 10px;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
}

.cart-icon {
  background-color: #2470c2;
  border-radius: 50%;
  color: #fff;
  height: 26px;
  width: 26.0625px;
  font-size: 14px;
}